




























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { SimpleUser } from "@/core/models";
import { defaultPfp } from "@/core/utils/constants";

@Component
export default class RenderSeminarUsers extends Vue {
  @Prop({ default: () => [] }) users!: SimpleUser[];

  defaultPfp = defaultPfp;
  showAllUsers = false;
  limit = 5;
  get hasMore() {
    return this.users.length > this.limit;
  }
  get moreText() {
    const count = this.users.length - this.limit;
    return `(+ ${count} more)`;
  }
  get toggleText() {
    if (this.showAllUsers) return "Hide all";
    return "Show all";
  }
  toggle() {
    this.showAllUsers = !this.showAllUsers;
  }
}
