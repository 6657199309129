




































































































































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Watch } from "vue-property-decorator";

import api from "@/core/utils/api";
import Pages from "@/core/utils/Pages";
import { ElementType, Seminar, Submission } from "@/core/models";
import PagesRender from "@/components/common/PagesRender.vue";
import VideoSubmission from "../components/VideoSubmission.vue";
import SeminarScores from "../components/SeminarScores.vue";
import RenderSeminarUsers from "../components/RenderSeminarUsers.vue";
import { RateVideoAction } from "@/core/models/requests";

const videoTypes: ElementType[] = ["video_presentation", "give_feedback"];

@Component({
  components: {
    PagesRender,
    VideoSubmission,
    SeminarScores,
    RenderSeminarUsers,
  },
})
export default class SeminarDashboard extends Vue {
  @Getter("profile/id") userId!: string;

  @Getter("seminars/loading") loading!: boolean;
  @Getter("seminars/selected") seminar?: Seminar;
  @Getter("seminars/submissions") submissions?: Submission[];
  @Getter("seminars/gettingFeedback") gettingFeedback!: boolean;
  @Getter("seminars/feedbackVideos") feedbackVideos?: Submission[];

  @Action("seminars/get") getSeminar!: (d: any) => Promise<void>;
  @Action("seminars/rateFeedbackVideo") rateFeedbackVideo!: (d: any) => void;
  @Action("seminars/getFeedbackVideos")
  getFeedbackVideos!: (id: number) => Promise<void>;

  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;

  get users() {
    return this.seminar?.users || [];
  }
  get isAdmin() {
    return this.seminar?.ownerId === this.userId;
  }

  repage() {
    if (!this.allVideos?.length) this.allPages = Pages.Empty();
    else this.allPages = new Pages(4, this.allVideos);
    if (!this.feedbackVideos?.length) this.feedbackPages = Pages.Empty();
    else this.feedbackPages = new Pages(2, this.feedbackVideos);
    if (!this.userSubmissions.length) this.userPages = Pages.Empty();
    else this.userPages = new Pages(2, this.userSubmissions);
  }

  allPages: Pages<Submission> = Pages.Empty();
  get allVideos() {
    return this.seminar?.submissions?.filter(
      x =>
        videoTypes.includes(x.type) && x.userId === this.userId && !x.skipped,
    );
  }
  @Watch("allVideos", { immediate: true })
  allVideosChanged() {
    if (!this.allVideos) this.allPages = Pages.Empty();
    else this.allPages = new Pages(4, this.allVideos);
  }

  feedbackPages: Pages<Submission> = Pages.Empty();
  @Watch("feedbackVideos", { immediate: true })
  feedbackVideosChanged() {
    if (!this.feedbackVideos) this.feedbackPages = Pages.Empty();
    else this.feedbackPages = new Pages(2, this.feedbackVideos);
  }

  goToSeminar() {
    if (!this.seminar) return;
    this.$router.push(`/seminars/details/${this.seminar.id}`);
  }

  goToAdmin() {
    if (!this.seminar) return;
    this.$router.push(`/seminars/edit/${this.seminar.id}`);
  }

  // todo maybe map with videos (save data on subsequent page loads)
  gettingUserSubmissions = false;
  userSubmissions: Submission[] = [];
  userPages: Pages<Submission> = Pages.Empty();
  async getSubmissions(id: number) {
    this.gettingUserSubmissions = true;
    try {
      const end = `/api/Submissions/SharedVideos/${id}`;
      const list = (await api.get(end)) as Submission[];
      this.userSubmissions = list;
      this.userPages = new Pages(2, list);
    } catch (error) {
      console.log(error);
    }
    this.gettingUserSubmissions = false;
  }

  rateFeedback(d: RateVideoAction) {
    if (!this.seminar) return;
    this.rateFeedbackVideo({ ...d, seminarId: this.seminar.id });
  }

  handleRated({ submissionId, type }: RateVideoAction) {
    const sub = this.userSubmissions.find(x => x.id === submissionId);
    if (!sub) return;
    if (sub.type !== "video_presentation" && sub.type !== "give_feedback")
      return;
    const ratings = sub.videoRatings?.slice(0) || [];
    const rating = ratings.find(
      x => x.rater === this.userId && x.type === type,
    );
    if (!rating)
      sub.videoRatings = [
        ...ratings,
        {
          id: (ratings[ratings.length - 1]?.id || 0) + 1,
          submissionId,
          type,
          rater: this.userId,
          createdAt: new Date().toISOString(),
        },
      ];
    else sub.videoRatings = ratings.filter(x => x.id !== rating.id);
  }

  @Watch("$route", { immediate: true })
  onRouteChange() {
    const param = this.$route.params.id as string | undefined;
    if (!param) {
      this.$router.replace("/seminars?from=dash");
      return;
    }

    const id = Number(param);
    this.getSeminar({ id });
    this.getFeedbackVideos(id);
    this.getSubmissions(id);
  }

  @Watch("submissions", { immediate: true })
  submissionsChanged() {
    this.repage();
  }
}
